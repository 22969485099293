.container {
  position: relative;
  background: lightgray;
  width: 50%;
  margin: 0 auto;
  height: 2000px;
}
.box-orange {
  position: fixed;
  background: orange;
  width: 100px;
  height: 100px;
  right: 5px;    
}
.box-blue {
  background: lightskyblue;
  height: 100px;
  width: 100px; 
}